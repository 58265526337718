import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/product";
export const PageQuery_product_nl_searchermdx = graphql`
        query PageQuery_product_nl_searchermdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "nl"}, ns: {in: ["common", "product"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/searcher/"},
                    language: {eq: "nl"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Zoek",
  "description": "Financiële zoekmachine",
  "path": "/product/searcher",
  "date": "2022-05-30T00:00:00.000Z",
  "template": "Product",
  "language": "nl",
  "icon": "bx-search-alt",
  "prio": 0,
  "tags": ["product", "search", "value", "money"]
};
const layoutProps = {
  PageQuery_product_nl_searchermdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      